.pt-button-group-filter {
  overflow-x: auto;
  scroll-snap-type: x proximity;
  max-width: 100vw;
  margin: 0 calc(var(--content-edge-gap) * -1) 2.875rem calc(var(--content-edge-gap) * -1);
  padding: 0 1.25rem;

  &__item {
    white-space: nowrap;
  }
}
