.pt-input {
  display: block;
  width: 100%;
  height: 2.625rem;
  padding: 0 0.75rem;
  color: var(--pt-color-black);
  border: 0.0625rem solid var(--pt-color-secondary-2);
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;

  &::placeholder {
    color: var(--pt-color-secondary-2);
  }

  &:focus {
    border-color: var(--pt-color-primary-1);
  }
}
