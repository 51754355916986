.pt-contact-page {
  display: block;
  padding-top: 3.75rem;
  text-align: center;

  .contact-page__title {
    margin-bottom: 1.5rem;
  }

  .contact-page__description {
    display: block;
    max-width: 37.5rem;
    margin: 0 auto 3.75rem auto;
  }

  .contact-page__form-section {
    margin-bottom: 3.75rem;
  }

  .contact-page__form-section-title {
    margin-bottom: 2.5rem;
  }

  .contact-page__details-section {
    text-align: left;
  }

  .contact-page__gmap {
    position: relative;
    width: 100%;
    height: 485px;
    margin-bottom: 2.5rem;
    text-align: right;
  }

  .contact-page__gmap-canvas {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 1.25rem;
    background: none !important;
  }
}

@media (--tablet-and-up) {
  .pt-contact-page {
    .contact-page__description {
      margin-bottom: 5rem;
    }

    .contact-page__form-section {
      margin-bottom: 5rem;
    }
  }
}

@media (--desktop-and-up) {
  .pt-contact-page {
    .contact-page__description {
      margin-bottom: 7.5rem;
    }

    .contact-page__content {
      display: flex;
      column-gap: 5rem;
    }

    .contact-page__form-section {
      flex: 1 0 0%;
      margin: 0;
      text-align: left;
    }

    .contact-page__details-section {
      flex: 1 0 0%;
    }
  }
}
