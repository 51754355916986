.pt-contact-form {
  display: block;
  max-width: 30rem;
  margin: 0 auto;
  text-align: left;

  &__title {
    font-weight: bold;

    .highlight {
      color: #0982fe;
    }
  }
}
