@import "./components/solutions-item";

.pt-solutions-page {
  padding-top: 4rem;
  text-align: center;

  .solutions-page__title {
    margin-bottom: 1.5rem;
  }

  .solutions-page__description {
    max-width: 56.25rem;
    margin: 0 auto 3rem auto;
  }

  .solutions-page__subtitle {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 0.125rem solid var(--pt-color-secondary-4);
    font-size: 1.625rem;
    line-height: 1.2;
  }

  .solutions-page__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

@media (--table-and-up) {
  .pt-solutions-page {
    .solutions-page__description {
      margin-bottom: 5rem;
    }
  }
}

@media (--desktop-and-up) {
  .pt-solutions-page {
    padding-top: 6rem;

    .solutions-page__description {
      margin-bottom: 7.5rem;
    }

    .solutions-page__subtitle {
      margin-bottom: 4rem;
      text-align: left;
      font-size: 2rem;
    }
  }
}
