:root {
  --max-content-width: 90rem; /* 1440px */
  --max-header-content-width: 101.25rem; /* 1620px */

  /* Content blocks */
  @media (--mobile-only) {
    --content-edge-gap: 1.25rem; /* 20px */
  }

  @media (--tablet-and-up) {
    --content-edge-gap: 2.5rem; /* 40px */
  }
}
