.animation-intro-slide-up {
  transform: translateY(40%);
  animation-name: intro-slide-up;
  animation-duration: 420ms;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: forwards;
  opacity: 0;

  @keyframes intro-slide-up {
    0% {
      transform: translateY(40%);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

/* Delay shortcuts */
.animation-delay-120 {
  animation-delay: 120ms;
}

.animation-delay-240 {
  animation-delay: 240ms;
}

.animation-delay-360 {
  animation-delay: 360ms;
}
