.solution-item {
  &__thumbnail {
    overflow: hidden;
    width: 100%;
    height: auto;
    margin-bottom: 1.5rem;
    border-radius: 0.75rem;
  }

  &__thumbnail-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__group-title {
    display: block;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
  }

  &__title {
    margin-bottom: 1.5rem;
  }

  &__descriprion ul {
    padding: 0;
  }

  &__description-list-item {
    display: flex;
    align-items: center;
    list-style-type: none;

    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }

    &::before {
      display: block;
      flex: 0 0 auto;
      width: 2rem;
      height: 2rem;
      margin-right: 0.75rem;
      content: "";
      background: url("/assets/images/icons/checkmark-icon.svg") center no-repeat;
    }
  }
}

@media (--tablet-and-up) {
  .solution-item {
    &__thumbnail {
      height: 25rem;
      margin: 0;
    }
  }
}

@media (--desktop-and-up) {
  .solution-item {
    &__content {
      padding-left: 3rem;
    }

    &__title {
      flex: 0 0 auto;
      font-size: 2.25rem;
      line-height: 1.2;
    }
  }
}

/* Revers the content when the item has even position */
@media (--desktop-and-up) {
  .solution-section__list-item:nth-child(even) {
    .solution-item > .row {
      flex-direction: row-reverse;

      .solution-item__content {
        padding-right: 3rem;
        padding-left: 0;
      }
    }
  }
}
