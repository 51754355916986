/* stylelint-disable color-function-notation */
:root {
  --pt-color-primary-1: #0583d2;
  --pt-color-primary-accent: #00bb37;
  --pt-color-secondary-1: #ffb703;
  --pt-color-secondary-2: #535353;
  --pt-color-secondary-3: #f4f4f4;
  --pt-color-secondary-4: #8b949e;
  --pt-color-secondary-5: #f1f7fe;
  --pt-color-danger: #bd1e21;
  --pt-color-white: #fff;
  --pt-color-black: #262727;

  /* State Hover */
  --pt-color-primary-1-hover: #43b7ff;
  --pt-color-primary-accent-hover: #01cd3e;
  --pt-color-secondary-2-hover: #f2f2f2;

  /* State Active */
  --pt-color-primary-1-active: #0077b3;
  --pt-color-primary-accent-active: #00a330;
}
