/*
  Base.
 */
@import "settings/init";
@import "typography/typography";
@import "base/base";
@import "utils/utils";

/*
  Layout.
 */
@import "layout/layout";

/*
  Components.
 */
@import "components/main";

/*
  Pages.
 */
@import "pages/home-page/main";
@import "pages/solutions-page/main";
@import "pages/contact-page/main";
@import "pages/about-page/main";
@import "pages/pricing-page/main";
@import "pages/install-page/main";
@import "pages/how-to-use-page/main";

/*
  Errors.
*/
@import "pages/error-page/404";
