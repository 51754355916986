@import "fonts";

:root {
  --primary-font: "Nunito Sans", sans-serif;
  --secondary-font: "Nunito Sans", sans-serif;
}

h2,
h3,
h4,
h5,
h6 {
  color: var(--pt-color-black);
}

.pt-display-2 {
  font-family: var(--primary-font);
  font-size: 3.75rem; /* 60px */
  font-weight: 800;
  line-height: 5rem; /* 80px */
}

.pt-heading {
  --font-weight: 400;
  --font-size: 1.625rem; /* 26px */
  --font-line-height: 2.25rem; /* 36px */

  color: var(--pt-color-secondary-2);
  font-family: var(--secondary-font);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--font-line-height);

  &--semibold {
    --font-weight: 600;
  }

  &--bold {
    --font-weight: 700;
  }

  &--fixed-small-size {
    --font-size: 1.625rem !important; /* 26px */
    --font-line-height: 2.25rem !important; /* 36px */
  }

  @media (--desktop-and-up) {
    --font-size: 3.75rem; /* 60px */
    --font-line-height: 5.125rem; /* 82px */
  }
}

.pt-heading-brand {
  --font-weight: 400;
  --font-size: 1.625rem; /* 26px */
  --font-line-height: 2.25rem; /* 36px */

  color: var(--pt-color-secondary-2);
  font-family: var(--primary-font);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  line-height: var(--font-line-height);

  &--semibold {
    --font-weight: 600;
  }

  &--bold {
    --font-weight: 800;
  }

  @media (--tablet-and-up) {
    --font-size: 2rem; /* 60px */
    --font-line-height: 2.625rem; /* 82px */
  }

  @media (--desktop-and-up) {
    --font-size: 3.75rem; /* 60px */
    --font-line-height: 5.125rem; /* 82px */
  }
}

.pt-subtitle {
  --font-weight: 400;

  font-family: var(--secondary-font);
  font-size: 2rem; /* 32px */
  font-weight: var(--font-weight);
  line-height: 1.3; /* 130% */

  &--semibold {
    --font-weight: 600;
  }

  &--bold {
    --font-weight: 700;
  }

  &--fixed-small-size {
    font-size: 1rem !important; /* 16px */
    line-height: 1.5rem !important; /* 24px */
  }

  @media (--desktop-and-up) {
    font-size: 2.25rem; /* 36px */
    line-height: 1.3; /* 130% */
  }
}

.pt-text {
  --font-weight: 400;

  margin: 0;
  color: var(--pt-color-secondary-2);
  font-family: var(--secondary-font);
  font-weight: var(--font-weight);

  &--semibold {
    --font-weight: 600;
  }

  &--bold {
    --font-weight: 700;
  }

  &--normal {
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
  }

  &--small {
    font-size: 0.875rem; /* 14px */
    line-height: 1.5rem; /* 24px */
  }
}

.pt-highloght-text {
  color: var(--pt-color-primary-1);
}

/* Typography layout spacing */
.pt-page-title {
  --page-title-vertical-space: 1.5rem; /* 24px */

  margin-bottom: var(--page-title-vertical-space);

  @media (--tablet-and-up) {
    --page-title-vertical-space: 2.5rem; /* 40px */
  }

  @media (--desktop-and-up) {
    --page-title-vertical-space: 5rem; /* 80px */
  }
}
