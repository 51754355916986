.get-started-form {
  .form__title {
    margin-bottom: 1.25rem;
  }

  .form__input-email {
    margin-bottom: 1.25rem;
  }

  .form__input-password {
    margin-bottom: 1.25rem;
  }
}

@media (--tablet-and-up) {
  .get-started-form {
    width: 100%;
  }
}

@media (--desktop-and-up) {
  .get-started-form {
    .form__title {
      margin-bottom: 2.5rem;
    }

    .form__input-password {
      margin-bottom: 2.5rem;
    }
  }
}
