.presentation-section {
  text-align: center;

  &--highlight {
    color: var(--pt-color-white);
    background-color: #f1f7fe;
  }

  &__title {
    max-width: 68.75rem;
    margin: 0 auto 1.25rem auto;
  }

  &__message {
    max-width: 47.5rem;
    margin: 0 auto 3.125rem auto;
  }

  &__subtitle {
    margin-bottom: 1.25rem;
  }

  &__brandlist {
    margin-bottom: 4rem;
    padding: 0;
    list-style: none;
    text-align: center;
  }

  &__brandlist-item {
    display: inline-block;
    max-width: 12.5rem;
    padding: 0.5rem 1rem;
  }

  &__brand-image {
    width: auto;
    height: 2rem;
  }

  &__list {
    list-style: none;
  }
}

@media (--desktop-and-up) {
  .presentation-section {
    &__brandlist {
      margin-bottom: 4.5rem;
    }
  }
}
