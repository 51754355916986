.solutions-item {
  display: block;
  padding-bottom: 3.75rem;
  text-align: left;

  &__thumbnail {
    overflow: hidden;
    width: 100%;
    margin-bottom: 1.5rem;
    border-radius: 0.75rem;
  }

  &__thumbnail-image {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: top left;
  }

  &__group-title {
    display: block;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
  }

  &__content {
    max-width: 26.875rem;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 1.5rem;
  }

  &__descriprion {
    margin-bottom: 1.5rem;
  }

  &__subtitle {
    margin-bottom: 1.25rem;
  }

  &__description-list-item {
    list-style-type: square;

    &:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }
}

@media (--tablet-and-up) {
  .solutions-item {
    padding-bottom: 5rem;
  }
}

@media (--desktop-and-up) {
  .solutions-item {
    display: flex;
    align-items: flex-start;
    padding-bottom: 7.5rem;

    &__thumbnail {
      flex: 1 0 auto;
      width: 28rem;
      height: 28rem;
      margin-right: 3rem;
      margin-bottom: 0;
    }

    &__thumbnail-image {
      width: 100%;
      height: 100%;
    }

    &__group-title {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }

    &__content {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      max-width: 100%;
    }

    &__title {
      flex: 0 0 auto;
      font-size: 2.25rem;
      line-height: 1.2;
    }

    &__descriprion {
      flex: 0 0 auto;
    }

    &__subtitle {
      margin-bottom: 2.5rem;
    }

    &__cta {
      flex: 0 0 auto;
      margin-top: auto;
    }
  }
}

/* Revers the content when the item has even position */
@media (--desktop-and-up) {
  .solutions-page__list-item:nth-child(even) {
    .solutions-item {
      flex-direction: row-reverse;

      &__thumbnail {
        margin-right: 0;
        margin-left: 3rem;
      }
    }
  }
}
