.pt-footer {
  display: block;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  color: #535353;
  background-color: #f4f4f4;

  .footer__content {
    margin-bottom: 2.5rem;
  }

  .footer__section {
    &:not(:last-child) {
      margin-bottom: 2.5rem;
      padding-bottom: 2.5rem;
      border-bottom: 0.0625rem solid var(--pt-color-white);
    }
  }

  .footer__brand-logo {
    display: block;
    width: 7.5rem;
    height: auto;
    margin: 0 auto 0.5rem auto;
  }

  .footer__brand-description {
    margin-bottom: 2.5rem;
    text-align: center;
    color: #535353;
  }

  .footer__social-list {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    padding: 0;
    list-style: none;
  }

  .footer__social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    text-decoration: none;
    border-radius: 100%;
    background-color: var(--pt-color-white);

    &:hover {
      background-color: var(--pt-color-primary-1-hover);
    }
  }

  .footer__social-icon {
    display: block;
    color: var(--pt-color-primary-1);
    font-size: 1.125rem;
  }

  .footer__info {
    display: flex;
  }

  .footer__info-group {
    flex: 1 0 0%;

    &:not(:last-child) {
      margin-right: 1.5rem;
    }

    &:last-child {
      flex: 2 0 0%;
    }
  }

  .footer__info-group-title {
    margin-bottom: 1.5rem;
    color: #535353;
  }

  .footer__info-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .footer__info-list-item:not(:last-child) {
    margin-bottom: 1rem;
  }

  .footer__info-link {
    text-decoration: none;
    color: #535353;

    &:hover,
    &:focus {
      text-decoration: underline;
      color: var(--pt-color-black);
    }
  }

  .footer__newsletter {
    max-width: 22.5rem;
    margin-bottom: 0.5rem;
  }

  .footer__newsletter-title {
    margin-top: -0.625rem !important;
    margin-bottom: 0.5rem;
    text-align: left !important;
    color: #535353;
  }

  .footer__reglementation-info {
    padding-top: 2.5rem;
    text-align: center;
    border-top: 0.0625rem solid #cbcbcb;

    .reglementation-text {
      color: #535353;
    }
  }
}

@media (--tablet-and-up) {
  .pt-footer {
    .footer__content {
      display: flex;
      flex-wrap: wrap;
    }

    .footer__presentation {
      display: flex;
      flex: 1 0 0%;
      flex-direction: column;
      width: 50%;
    }

    .footer__brand-logo {
      flex: 0 0 0%;
      margin: 0 0 0.5rem 0;
    }

    .footer__brand-description {
      flex: 0 0 0%;
      text-align: left;
    }

    .footer__social-list {
      flex: 0 0 0%;
      justify-content: flex-start;
      margin-top: auto;
    }

    .footer__info {
      flex: 1 0 0%;
      width: 50%;
    }

    .footer__newsletter {
      display: flex;
      width: 100%;
    }
  }
}

@media (--desktop-and-up) {
  .pt-footer {
    .footer__section:not(:last-child) {
      margin-right: 1.5rem;
      margin-bottom: 0;
      border-bottom: 0;
    }

    .footer__brand-description {
      max-width: 10rem;
    }

    .footer__presentation {
      flex: 1 0 0%;
      max-width: 16.25rem;
    }
    .footer__info {
      flex: 1 0 0%;
    }

    .footer__newsletter {
      flex: 1 0 0%;
      flex-direction: column;
    }
  }
}
