body.pt-app {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
  touch-action: manipulation;
}

.pt-content {
  flex: 1;
  margin-bottom: 3.75rem;

  @media (--tablet-and-up) {
    margin-bottom: 80px;
  }

  @media (--desktop-and-up) {
    margin-bottom: 7.5rem;
  }
}

.pt-content-block {
  max-width: var(--max-content-width);
  margin: 0 auto 0 auto;
  padding-right: var(--content-edge-gap);
  padding-left: var(--content-edge-gap);

  &--no-vertical-margin {
    margin-bottom: 0 !important;
  }

  @media (--tablet-and-up) {
    /* margin: 0 auto 5rem auto; */
  }

  @media (--desktop-and-up) {
    /* margin: 0 auto 7.5rem auto; */
  }
}

/* Fix old layout */
.cont-t1 .auto {
  max-width: 100% !important;
}

.cont-t1 .auto .con {
  display: block;

  @media (--tablet-and-up) {
    display: flex;
  }
}

.cont-t1 .auto .con .left {
  flex: 0 0 auto;
}

.cont-t1 .auto .con .left .pad {
  position: sticky;
  top: 2.5rem;
}

.cont-t1 .auto .con .right {
  flex: 1 1 auto;
  width: auto;
}

.cont-t1 .auto .con .clear {
  display: none !important;
}
