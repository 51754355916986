.pt-usage-page {
  padding-top: 3.75rem;

  .usage__section {
    display: flex;
    gap: 5rem;
    align-items: center;
    justify-content: center;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }

    &:not(:last-child) {
      margin-bottom: 5rem;
    }
  }

  .usage__section-content {
    flex: 0 0 auto;
    width: 17.5rem;
  }

  .usage__section-title {
    margin-bottom: 2rem;
    color: var(--pt-color-primary-1);
    font-weight: 600;
  }

  .usage__section-description {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;
  }

  .usage__section-thumbnail {
    overflow: hidden;
    flex: 0 0 auto;
    border: 0.1875rem solid var(--pt-color-primary-1-hover);
    border-radius: 0.625rem;
  }

  .usage__section-thumbnail-image {
    width: auto;
    height: 28.125rem;
  }
}
