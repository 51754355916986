.testimonials-section {
  &__title {
    text-align: center;
  }
}

@media (--desktop-and-up) {
  .testimonials-section {
    .pt-carousel {
      &--desktop {
        display: block;
      }

      &--mobile {
        display: none;
      }
    }
  }
}
