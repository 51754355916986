.testimonial-card {
  max-width: 25rem;
  margin: 0 auto;
  padding: 0.5rem;

  &__header {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 1.5rem;
  }

  &__name {
    color: var(--pt-color-black);
    font-weight: 600;
  }

  &__role {
    color: var(--color-text-secondary);
  }

  &__avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }

  &__body {
    display: block;
  }

  &__rating {
    width: auto;
    height: 1.375rem;
    margin-bottom: 0.5rem;
    background-image: url("/assets/images/rating-stars.png");
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__feedback-text {
    padding-left: 1.5rem;
    color: var(--pt-collr-black);
    background-image: url("/assets/images/icons/quote-icon.svg");
    background-repeat: no-repeat;
    background-position: 0.25rem 0.25rem;
    background-size: 0.75rem;
  }
}
