/* stylelint-disable unit-allowed-list */

* {
  box-sizing: border-box;
  font-family: var(--secondary-font);
}

html {
  color: var(--pt-color-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}

body {
  margin: 0;
}
