.pt-pricing-card {
  display: flex;
  flex-direction: column;
  height: 32rem;
  padding: 1.25rem;
  border-radius: 0.5rem;
  background: var(--pt-color-white);
  box-shadow: 0 0.5rem 1rem -0.375rem rgba(24 39 75 / 8%), 0 0.375rem 0.5rem -0.375rem rgba(24 39 75 / 0%);

  .pricing-card__title {
    flex: 0 0 auto;
    text-transform: capitalize;
    font-size: 1.5rem;
    font-weight: 700;
    font-style: normal;
    line-height: 2.25rem; /* 150% */
  }

  .pricing-card__price {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    color: var(--pt-color-primary-1);
    font-size: 1.5rem;
    font-weight: 700;
    font-style: normal;
    line-height: 2.25rem; /* 150% */
  }

  .pricing-card__price-label {
    display: block;
    padding-left: 0.375rem;
    font-size: 1.5rem;
    font-weight: 500;
    font-style: normal;
    line-height: 2.25rem; /* 150% */
  }

  .pricing-card__list-wrapper {
    display: block;
    flex: 0 0 auto;
    padding-bottom: 1rem;
    padding-left: 1.25rem;
  }

  .pricing-card__list {
    padding: 0;
  }

  .pricing-card__list-item {
    display: flex;
    align-items: center;
    list-style-type: none;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &::before {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.25rem;
      content: "";
      background-image: url("/assets/images/icons/check-circle-icon.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      font-size: 0.75rem;
      line-height: 1;
    }
  }

  .pricing-card__list-item--unsuported {
    opacity: 0.5;

    &::before {
      padding-bottom: 0.0625rem;
      content: "x";
    }
  }

  .pricing-card__cta {
    flex: 0 0 auto;
    margin-top: auto;
  }

  &--primary {
    .pricing-card__price {
      color: var(--pt-color-primary-accent);
    }
  }

  &--highlighted {
    position: relative;
    // prettier-ignore
    box-shadow:
      0 -0.25rem 1rem -0.375rem rgba(24 39 75 / 7%),
      0 0.375rem 1.4375rem -0.375rem #b4daf2,
      0 0.25rem 0.25rem 0 #a2bfd0;
  }
}

@media (--tablet-and-up) {
  .pt-pricing-card {
    padding: 2rem;

    .pricing-card__list-wrapper {
      padding-left: 0;
    }
  }
}

@keyframes highlight-animation-slide-down {
  0% {
    transform: translate(-50%, 0);
    opacity: 0;
  }

  100% {
    transform: translate(-50%, -100%);
    opacity: 1;
  }
}
