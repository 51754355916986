.pricing-section {
  padding-top: 3.75rem;

  &__title {
    margin-bottom: 1.5rem;
    text-align: center;
  }

  &__title-description {
    text-align: center;
  }

  &__description {
    display: block;
    margin: 0 auto 1.5rem auto;
  }

  &__listing {
    padding: 2rem 0;
    background-color: var(--pt-color-secondary-5);
  }

  &__disclaimer {
    margin-bottom: 4rem;
    padding-top: 1rem;
    text-align: center;

    .disclaimer-message {
      text-align: left;
      color: var(--pt-color-secondary-4);
      font-style: italic;
    }
  }
}

@media (--desktop-and-up) {
  .pricing-section {
    &__description {
      margin: 0 auto 3.75rem auto;
    }
  }
}
