.pt-install-page {
  .install-page__title {
    text-align: center;
  }

  .install-page__content {
    max-width: 56.25rem;
    margin: 0 auto;
  }

  .install-page__list {
    padding-top: 2.625rem;
    padding-bottom: 2rem;

    li {
      margin-bottom: 0.75rem;
    }
  }
}
