.hero-section {
  position: relative;
  margin-bottom: 1.5rem;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  text-align: center;
  background-image: url("/assets/images/home-page/hero-bg.jpg");
  background-size: cover;

  &__wrapper {
    margin-bottom: 0;
  }

  &__intro {
    max-width: 43.75rem;
    margin-bottom: 2.5rem;
  }

  &__title {
    color: var(--pt-color-white);
  }

  &__subtitle {
    margin-bottom: 2rem;
    color: var(--pt-color-white);
    font-size: 1.5rem !important;
    font-weight: 1.5 !important;
    font-weight: 600 !important;
  }

  &__illustration {
    display: none;
  }
}

@media (--tablet-only) {
  .hero-section {
    margin-bottom: 3rem;
    padding-top: 2.625rem;

    &__intro {
      max-width: 37.5rem;
      margin: 0 auto 2.5rem;
    }

    &__form {
      max-width: 27.25rem;
      margin: 0 auto;
    }
  }
}

@media (--tablet-and-up) {
  .hero-section {
    padding-top: 2.625rem;
  }
}

@media (--desktop-and-up) {
  .hero-section {
    margin-bottom: 5rem;
    padding-top: 7.5rem;
    text-align: left;

    &__intro {
      margin: 0;
      padding-right: 4.5rem;
    }

    &__form {
      padding-top: 4rem;
    }

    &__illustration {
      display: block;

      .codeout-t2 {
        overflow-y: auto;
        height: 28.125rem;
        border-radius: 0.5rem;
        background-color: var(--pt-color-white);

        .bfnum {
          background-color: #489fd5;
        }

        /* .illustration__title {
          position: sticky;
          z-index: 9;
          top: 0;
          margin: 0;
          padding: 1.25rem 2.125rem;
          text-align: center;
          text-transform: uppercase;
          background-color: var(--pt-color-white);
          font-weight: 600;
        } */

        .inn {
          color: var(--pt-color-black);
          font-weight: 700;

          .x1 {
            font-weight: 400;
          }
        }

        .jsonmarkup {
          border: 0;

          .lines {
            border: 0;
            background-color: var(--pt-color-primary-1);

            .num {
              color: rgb(255 255 255 / 60%);
            }
          }
        }
      }
    }
  }
}
