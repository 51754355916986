.faq-section {
  margin-right: auto;
  margin-left: auto;

  &__title {
    margin-bottom: 2rem;
    text-align: center;
  }

  &__accordion-body {
    padding: 2.5rem 2.5rem;
  }

  &__install-list {
    list-style-type: decimal;

    li {
      margin-bottom: 0.5rem;
    }
  }

  /* Over Bootstrap */
  .accordion-button {
    padding: 2.5rem 2.5rem;
    font-size: 1.5rem;
    font-weight: 700;
    font-style: normal;
    line-height: 2.25rem; /* 150% */
  }
}

@media (--desktop-and-up) {
  .faq-section {
    &__title {
      margin-bottom: 3.75rem;
    }
  }
}
