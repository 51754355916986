/* Z indexes */
:root {
  --z-index-flash-message: 2000;
  --z-index-search: 1001;
  --z-index-modal: 1000;
  --z-index-header: 100;
  --z-index-broadcast-message: 99;
  --z-index-dropdown: 98;
  --z-index-tooltip: 98;
  --z-index-sticky-content: 10;
  --z-index-above-foreground: 2;
  --z-index-foreground: 1;
  --z-index-background: 0;
  --z-index-under-background: -1;
}
