.including-section {
  max-width: 60rem;
  margin-right: auto;
  margin-left: auto;

  &__title {
    margin-bottom: 2rem;
    text-align: center;
  }

  &__list {
    display: block;
    width: 15rem;
    margin: 0 auto;
    padding: 0;
    list-style: none;
  }

  &__list-item {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;

    &::before {
      display: block;
      flex: 0 0 auto;
      width: 2rem;
      height: 2rem;
      margin-right: 0.75rem;
      content: "";
      background: url("/assets/images/icons/checkmark-icon.svg") center no-repeat;
    }
  }
}
