.pt-error-404 {
  padding-top: 3.75rem;
  text-align: center;

  .error__content {
    max-width: 43.75rem;
    margin: 0 auto;
  }

  .error__placeholder-image {
    width: 100%;
    max-width: 10rem;
    height: auto;
    margin: 0 auto 1.25rem auto;
  }

  .error__cta-button {
    margin-top: 1.25rem;
  }
}

@media (--tablet-and-up) {
  .pt-error-404 {
    .error__placeholder-image {
      max-width: 15rem;
      margin: 0 auto 2.5rem auto;
    }

    .error__cta-button {
      margin-top: 2.5rem;
    }
  }
}

@media (--desktop-and-up) {
  .pt-error-404 {
    padding-top: 7.5rem;

    .error__content {
      max-width: 50rem;
    }

    .error__placeholder-image {
      /* max-width: 21.875rem; */
      margin: 0 auto 3.75rem auto;
    }

    .error__cta-button {
      margin-top: 3.75rem;
    }
  }
}
