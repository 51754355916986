.integration-section {
  text-align: center;

  &__content {
    padding: 2.5rem;
    border-radius: 1.25rem;
    background-color: var(--pt-color-primary-1);
  }

  &__title {
    margin-bottom: 1.25rem;
    color: var(--pt-color-white);
  }

  &__message {
    margin-bottom: 2.5rem;
    color: var(--pt-color-white);
  }

  &__logo-image {
    display: block;
    width: 100%;
    max-width: 18.75rem;
    height: auto;
    margin: 2.5rem auto 0;
  }
}

@media (--tablet-and-up) {
  .integration-section {
    text-align: left;

    &__content {
      padding: 2.5rem;
    }
  }
}

@media (--desktop-and-up) {
  .integration-section {
    &__content {
      padding: 3.75rem 7.5rem;
    }

    &__logo-image {
      max-width: 100%;
    }
  }
}
