.pt-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
  padding: 0.75rem 1rem;
  transition: all 0.1s ease-in-out;
  text-align: center;
  text-decoration: none !important;
  color: var(--pt-color-white);
  border: 0;
  border-radius: 0.5rem;
  background-color: var(--pt-color-primary-accent);
  font-family: var(--primary-font);
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.5;

  &:hover {
    color: var(--pt-color-white);
    background-color: var(--pt-color-primary-accent-hover);
  }

  &:active {
    color: var(--pt-color-white);
    background-color: var(--pt-color-primary-accent-active);
  }

  &--large {
    padding: 1.25rem 6.625rem;
    font-size: 1.25rem;
    line-height: 1;
  }

  &--primary {
    color: var(--pt-color-white);
    background-color: var(--pt-color-primary-1);

    &:hover {
      color: var(--pt-color-white);
      background-color: var(--pt-color-primary-1-hover);
    }

    &:active {
      color: var(--pt-color-white);
      background-color: var(--pt-color-primary-1-active);
    }
  }

  &--secondary {
    color: var(--pt-color-primary-1);
    border: 0.0625rem solid var(--pt-color-primary-1);
    background-color: var(--pt-color-white);

    &:hover {
      color: var(--pt-color-primary-1-hover);
      border-color: var(--pt-color-primary-1-hover);
      background-color: var(--pt-color-white);
    }

    &:active {
      color: var(--pt-color-primary-1-active);
      border-color: var(--pt-color-primary-1-active);
      background-color: var(--pt-color-white);
    }
  }

  &[disabled] {
    pointer-events: none !important;
    opacity: 0.3 !important;
  }

  &--wide {
    display: block;
    width: 100%;
  }

  &--mobile-wide {
    @media (--mobile-only) {
      display: block;
      width: 100%;
    }
  }
}
