.get-started-section {
  text-align: center;

  &__content {
    padding: 2.5rem 1.25rem;
    border-radius: 1.25rem;
    background-color: var(--pt-color-secondary-3);
  }

  &__intro {
    margin-bottom: 2rem;
  }

  &__subtitle {
    display: block;
    margin-bottom: 0.5rem;
  }

  &__title {
    margin-bottom: 1.25rem;
    color: var(--pt-color-primary-1);
  }
}

@media (--tablet-and-up) {
  .get-started-section {
    text-align: left;

    &__content {
      padding: 2.5rem;
    }

    &__intro {
      margin-bottom: 0;
    }

    &__form {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
}

@media (--desktop-and-up) {
  .get-started-section {
    &__content {
      padding: 3.75rem 7.5rem;
    }
  }
}
