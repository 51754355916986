.value-card {
  --card-width: 100%;
  --card-height: auto;
  --icon-size: 3rem;

  justify-content: flex-start;
  max-width: var(--card-width);
  height: var(--card-height);
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;

  &__icon {
    display: block;
    flex: 0 0 auto;
    width: auto;
    height: var(--icon-size);
    margin-bottom: 0.5rem;
  }

  &__title {
    flex: 0 0 auto;
    margin-bottom: 1rem;
    color: var(--pt-color-black);
    font-weight: 600;
  }

  &__description {
    flex: 0 0 auto;
    margin: 0;
    color: var(--pt-color-black);
  }
}

@media (--desktop-and-up) {
  .value-card {
    &__icon {
      display: block;
    }
  }
}
