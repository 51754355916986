.pt-header {
  position: sticky;
  z-index: var(--z-index-header);
  top: 0;
  display: block;
  transition: box-shadow 0.2s ease-in;
  background-color: #fff;

  &--sticky-shadow {
    /* stylelint-disable-next-line  */
    box-shadow: 0 0.25rem 0.25rem rgb(0 0 0 / 10%);
  }

  .pt-navbar {
    width: 100%;
    margin: 0 auto;
  }

  .pt-navbar-nav {
    align-items: flex-start;
  }

  .pt-nav-item {
    padding: 0.875rem 0;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    &:hover {
      color: var(--pt-color-primary-1);
    }
  }

  .pt-nav-link {
    color: currentcolor;
    font-weight: 600;
  }

  .pt-nav-dropdown-menu {
    border: 0;
    background-color: transparent;
  }

  .pt-nav-dropdown-item {
    color: var(--pt-color-white);
    background-color: transparent;
  }

  .pt-navbar-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: var(--max-content-width);
    margin: 0 auto;
    padding-right: var(--content-edge-gap);
    padding-left: var(--content-edge-gap);
  }
}

@media (--tablet-and-down) {
  .pt-header {
    .pt-navbar-brand {
      margin: 0;
    }

    .pt-navbar-brand-image {
      display: block;
      width: 7.5rem;
      height: auto;
    }

    .pt-navbar-content {
      flex-flow: row-reverse;
    }

    .pt-nav-item {
      width: 100%;
      margin-right: 0;
      padding: 0.875rem 0;
      text-transform: uppercase;
    }

    .pt-nav-link {
      display: inline-block;
      width: auto;
      color: var(--pt-color-white);
    }

    .pt-navbar-toggle-button {
      padding: 0.5rem;
      border: 0;
    }

    .pt-navbar-toggler-icon {
      width: 1.5rem;
      height: 1.5rem;
    }

    .pt-navbar-collapse {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 3.75rem;
      background-color: var(--pt-color-primary-1);
      background-size: cover;
    }

    .pt-navbar-collapse-header {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 3.75rem;
    }

    .pt-navbar-collapse-logo {
      display: block;
      flex: 0 0 auto;
      width: 7.5rem;
      height: auto;
    }

    .pt-navbar-close-button {
      position: absolute;
      top: 1.25rem;
      right: 1.25rem;
      flex: 0 0 auto;
      width: 1.5rem;
      height: 1.5rem;
      margin-left: auto;
      padding: 0;
      color: #fff;
      border: 0;
      background-color: transparent;
    }

    .pt-nav-cta {
      color: var(--pt-color-black);
      background-color: var(--pt-color-white);
    }
  }
}

@media (--desktop-and-up) {
  .pt-header {
    .pt-navbar {
      padding: 0;
    }

    .pt-navbar-brand-image {
      width: 9.875rem;
      height: auto;
    }

    .pt-navbar-nav {
      flex: 1 1 auto;
      justify-content: center;

      &--end {
        flex: 0 0 auto;
        justify-content: flex-end;
        width: 10.875rem;
      }
    }

    .pt-nav-dropdown-menu {
      border-top: 0.1875rem solid var(--pt-color-primary-1);
      border-radius: 0.25rem;
      background-color: var(--pt-color-white);
      box-shadow: 0 0.25rem 0.25rem rgb(0 0 0 / 10%);
    }

    .pt-nav-dropdown-item {
      color: var(--pt-color-black);

      &:hover {
        background-color: var(--pt-color-secondary-2-hover);
      }
    }

    .pt-navbar-collapse-header {
      display: none;
    }
  }
}
