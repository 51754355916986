.pt-about-page {
  position: relative;
  display: block;
  padding-top: 3.75rem;
  text-align: center;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0.1;
    background-image: url("/assets/images/about-page/bg.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .text-block {
    max-width: 51.25rem;
    margin: 0 auto;
    font-size: 1rem;
    font-style: normal;
    line-height: 1.5rem; /* 150% */
  }
}
